import React, { useState } from 'react';
import Typewriter from 'typewriter-effect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './first.scss';

const data = [
  'Ha pasado poco mas de un año desde que nos volvimos a encontrar ✨',
  'Nos hemos vuelto a conocer',
  'Hemos descubierto que compartimos muchos pasatiempos',
  'Y hemos descubierto algunos nuevos en el proceso 💖',
];

const First = ({ setStep }) => {
  const [showArrow, setShowArrow] = useState();
  return (
    <div className="f-block first">
      <Typewriter
        options={{
          loop: false,
          autoStart: true,
          delay: 75,
        }}
        onInit={(typewrite) => {
          //dont delete chart at the end\
          data.forEach((text, idx) => {
            let typer = typewrite
              .typeString(text + (idx === data.length - 1 ? '' : '<br/>'))
              .start()
              .pauseFor(500);
            if (idx === data.length - 1) {
              typer
                .callFunction(() => setShowArrow(true))
                .stop()
                .pauseFor(Infinity);
            }
          });
        }}
      />
      <a href='#second' onClick={() => setStep(2)} className='arrow-cont'>
        {showArrow && <FontAwesomeIcon className="arrow" icon={faArrowRight} />}
      </a>
    </div>
  );
};

export default First;
