import React, { useState, useEffect } from 'react';
import ReactTextTransition, { presets } from 'react-text-transition';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './hero.scss';

const titleTexts = ['Nosotros hemos'];

const paragraphs = [
  'compartido algunos momentos hermosos',
  'escuchado muchas canciones, compartido juegos 🕹️',
  'visto muchas series, peliculas y animes 📺',
  'y aún quedan muchas vas para ver mi Mayi preciosa! ❤️',
];

const Home = ({ step, setStep }) => {
  const [idxTexts, setIdxTexts] = useState({
    title: 0,
    paragraph: 0,
  });
  useEffect(() => {
    if (step === 2) {
      setInterval(() => {
        setIdxTexts((prev) => {
          if (prev.paragraph === paragraphs.length - 1) {
            return prev;
          }
          return {
            title: prev.title + 1,
            paragraph: prev.paragraph + 1,
          };
        });
      }, 6000);
    }
  }, [step]);
  return (
    <div id="second" className="f-block first">
      {step === 2 && (
        <section className="inline">
          <div className="big">Nosotros hemos...</div>
          <ReactTextTransition
            text={paragraphs[idxTexts.paragraph % paragraphs.length]}
            springConfig={presets.gentle}
          />
        </section>
      )}
      {
        (step === 2)  &&
        (idxTexts.paragraph % paragraphs.length) === paragraphs.length - 1 && (
        <a href="#third" onClick={() => setStep(3)} className="arrow-cont">
          <FontAwesomeIcon className="arrow" icon={faArrowRight} />
        </a>
        )
      }
    </div>
  );
};

export default Home;
