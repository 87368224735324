import React from 'react';
import gsap from 'gsap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import './third.scss';

const Third = ({ step, setStep }) => {
  const [hideText, setHideText] = React.useState();
  const [hideA, setHideA] = React.useState();
  const showText = () => {
    setHideText(true);
    setTimeout(() => setHideA(true), 900);
    const text = gsap.timeline({ delay: 1 }),
      firstBg = document.querySelectorAll('.text__first-bg'),
      secBg = document.querySelectorAll('.text__second-bg'),
      word = document.querySelectorAll('.text__word');

    text
      .to(firstBg, 0.5, { scaleX: 1 })
      .to(secBg, 0.5, { scaleX: 1 })
      .to(word, 0.3, { opacity: 1 }, '-=0.1')
      .to(firstBg, 0.5, { scaleX: 0 })
      .to(secBg, 0.3, { scaleX: 0 });
  };
  return (
    <div id="third" className="f-block third">
      <p className="text">
        <span className="text__first">
          <span className="text__word" >
            ¿Quieres seguir siendo
          </span>
          <span className="text__first-bg"></span>
        </span>
        <br />
        <span className="text__second">
          <span className="text__word" >
            siempre mi <div style={{display:'inline', color: '#e63369'}} >San Valentín?</div>
          </span>
          <span className="text__second-bg"></span>
        </span>
      </p>
      <div style={{ minHeight: '70px' }}>
        {!hideA ? (
          <button
            onClick={() => showText()}
            className={hideText ? 'custom-btn btn-5 fade-out' : 'custom-btn btn-5'}
            type="button"
          >
            Es por eso que te quiero preguntar si...
          </button>
        ) : (
          <a href="#end">
            <FontAwesomeIcon
              onClick={() => setStep(4)}
              className="heart-icon fade-in"
              icon={faHeart}
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default Third;
