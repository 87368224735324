import React, { useEffect, useState } from 'react';
import './App.scss';
import useHorizontal from '@oberon-amsterdam/horizontal/hook';
import Home from './components/sections/home';
import Hero from './components/sections/hero';
import First from './components/sections/first';
import Third from './components/sections/third';
//import Sound from 'react-sound';
//import Eva192 from './data/eva_192.mp3';
import Eva320 from './data/eva_320.mp3';
import useSound from 'use-sound';

const App = () => {
  const [container, setContainer] = useState();
  const [step, setStep] = useState();
  const [play] = useSound(Eva320);
  const [isPlaying, setIsPlaying] = useState();
  useHorizontal({
    container,
    scrollAmount: 200,
    scrollAmountStep: 2000,
  });
  useEffect(() => {
    if (step === 4 && container) {
      container?.scrollIntoView({ behavior: 'smooth' });
      //scrol to the end of container
      container.scrollTop = container?.scrollWidth;
    }
  }, [step, isPlaying]);
  const handleClick = () => {
    setTimeout(() => {
      setIsPlaying(true);
      play();
    }, 2000);
  };
  return (
    <div className="container-hook" ref={(ref) => setContainer(ref)}>
      {
        isPlaying ? (
          <>
            <First play={play} step={step} setStep={setStep} />
            <Hero step={step} setStep={setStep} />
            <Third step={step} setStep={setStep} />
            <Home step={step} setStep={setStep} />
          </>
        ) : (
          <div className='f-block'>
            <label onClick={handleClick} class="like">
              <input type="checkbox"/>
              <div class="hearth"/>
            </label>
          </div>
        )
      }
    </div>
  );
};

export default App;
