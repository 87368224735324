import React, { useEffect, useRef } from 'react';
import ParticlesText from './particleText';
import BubblesText from './bubblesText';



const Home = ({ step }) => {
  const container = useRef();

  useEffect(() => {
    /* if (container.current) {
      const particles = new ParticlesText({
        text: "Flim", size: 175, contCanvas: container.current
      });
      particles.init();
    } */
    if (step == 4) {
      const bubleText = new BubblesText(container.current);
      bubleText.initScene();
      bubleText.render();
    }
  }, [ step ]);

  return (
    <>
      <div id='end' className="f-block">
        <canvas ref={container}>
        </canvas>
      </div>
    </>
  );
};

export default Home;
